import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Loader from '../../Loader/Loader';

const FullPostBlog = () => {
    const { id } = useParams();
    const [post, setPost] = useState({});
    const location = useLocation();


    useEffect(()=>{
        // axios
        let url = `${process.env.REACT_APP_API_ROOT}/posts/${id}`;

        axios.get(url).then(res => {
            setPost(res.data);
        }).catch(err => {
            console.log('error:', err.message);
        });
    }, [id, location.pathname]);

    const parseContent = (content) => {
        return content.replace(/style="([^"]*)"/g, (match, styles) => {
            return `style="${styles}"`;
        });
    };
    console.log('post:', Object.keys(post).length)

    return (
        <div id='fullBlogPost' className='w-full h-full bg-primary-50 py-12'>
            {
                Object.keys(post).length ? (
                    <div 
                    className='z-50 p-5 flex flex-col justify-center items-center  mx-auto  w-[80%]'
                    >
                    <div className='mb-4 text-green-200 '
                    >
                            <h1 className='text-[36px]  md:text-[28px] sm:text-[24px]  font-bold text-center '>
                                {post.title.rendered}
                            </h1>
                        </div>

                        <div className='max-w-md mb-12'>
                            <img className='w-full' alt='featured' src={post.featured_src} />
                        </div>
                        
                       
                        <div 
                        className='flex flex-col gap-3 w-[80%] lg:w-[100%]'
                         dangerouslySetInnerHTML={{ __html: parseContent(post.content.rendered) }}
                        >
                            
                         </div>

                    </div>
                ) : (<Loader />)
            
            }
        </div>
    )
}

export default FullPostBlog

