import React,{useEffect,useState} from 'react'
import CategoryNameDisplayUi from './CategoryNameDisplayUi'
import { Link } from 'react-router-dom'
import axios from 'axios'

const CategoryNameDisplay = () => {
  const [categories, setCategories] = useState([]);
  const api = process.env.REACT_APP_API_ROOT;
  useEffect(() => {
    axios.get(`${api}/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, []);
  return (
    <div>
    <h1 className='text-center text-cinnemon font-bold text-[56px] 2sm:text-[42px] font-["Bright"] py-4'>Categories</h1>
     {
        categories.map((category,index) => {
          return (
            <Link key={category.id} to={`/category/${category.id}`}><CategoryNameDisplayUi category={`${category.name}`} />
            </Link>
          )
        })
     }
  </div>
  )
}

export default CategoryNameDisplay