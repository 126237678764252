const ProjectsData = [
  {
    "title":"lydiawritesgood",
    "imgSrc":"images/webdesign/iydia.png",
    "demoUrl":"https://www.lydiawritesgood.com/",
  },
  {
    "title":"pefa",
    "imgSrc":"images/webdesign/pefa.png",
    "demoUrl":"https://www.pefanetwork.com/",
  },
  // {
  //   "title":"vanderbilt",
  //   "imgSrc":"images/wordpress/vanderbilt-edu.png",
  //   "demoUrl":"https://www.vanderbilt.edu/",
  // },
  {
    "title":"michelleforboston",
    "imgSrc":"images/react/michelleforboston.png",
  //   "demoUrl":"https://toolkit.michelleforboston.com/",
    "demoUrl":"https://www.michelleforboston.com/",
},
// {
//   "title":"rentACarHub",
//   "imgSrc":"images/react/RentACarHub.png",
//   "demoUrl":"https://rent-a-car-hub.vercel.app/",
// },


]

export default ProjectsData;