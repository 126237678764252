import { createContext,useState } from "react";
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
// import {toast} from 'react-toastify';

//create context
export const AppContext = createContext();

//create context provider
export default function AppContextProvider({children}){
    const api = process.env.REACT_APP_API_ROOT;

    // console.log("api",api)

    const [posts, setPosts] = useState([]);
    const [recentPosts, setRecentPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    
    const navigate = useNavigate();

//fetch recent posts
    const fetchRecentPosts = async () =>{
        setLoading(true);
        try {
            const response = await axios.get(`${api}/posts`)
            
            setRecentPosts(response.data);
            //check if no posts
            if(response.data.length === 0){
                throw new Error ('No posts found');
            }
            // console.log(response.data);

        } catch (error) {
            // toast.error('Something went wrong');
            // console.log(error);
        }
        setLoading(false);
    }



    //fetch blog posts
    const fetchBlogPosts = async (category) =>{
        setLoading(true);
        // console.log("category number",category);

        let url = `${api}/posts`;
       
        if(category){
            url = `${api}/posts?categories=${category}`;
            
        }

        try {
            // console.log(url)
            const response = await axios.get(url);
            setPosts(response.data);
            //check if no posts
            if(response.data.length === 0){
                throw new Error ('No posts found');
            }
            // console.log(response.data);

        } catch (error) {
            // toast.error('Something went wrong');
            // console.log(error);
        }
        setLoading(false);
    }

    const value = {
        posts,
        setPosts,
        loading,
        setLoading,
        fetchBlogPosts,
        categories,
        setCategories,
        fetchRecentPosts,
        recentPosts,
        setRecentPosts,
    }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider> 
}