import React,{useContext} from 'react'
import Loader from '../../Loader/Loader'
import { AppContext } from '../../../context/AppContext'
import { Link } from 'react-router-dom'

const RecentPosts = ({img,width}) => {
  
  const {recentPosts,loading} = useContext(AppContext);
   const formatDate = (date) => {
    const options = {month: 'long', day: 'numeric', year: 'numeric'};
    return new Date(date).toLocaleDateString('en-US', options);
    }

  return (
    <>
    
        {
            loading ? <Loader/> : Object.keys(recentPosts).length ? recentPosts.slice(0,3).map(post =>{
                return(
                   <div className='text-green-300 mb-8 2xl:w-[70%] 2sm:w-[300px] 3sm:w-[200px] w-[400px]'  key={post.id}>
                   <Link to={`/blog/${post.id}`}>
                     {img && <img src={post.featured_src ? post.featured_src : 'https://via.placeholder.com/500'} alt={post.title.rendered} className="w-full h-64 object-cover mb-4" />}
                     <h1 className='font-bold '>{post.title.rendered}</h1>
                     <p>{Array.isArray(post.category_names) ? post.category_names.join(', ') : ''}</p>
                     <p className='text-green-300/50'>{formatDate(post.modified)}</p>
                     </Link>
                   </div>
                )
            }):
            <div>
                <p>No posts</p>
            </div>
        }
    </>
   
    
  )
}

export default RecentPosts