import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from 'react-router-dom'

const Process = () => {
  return (
    <div className='bg-green-200 flex justify-center flex px-12 py-24 font-bold md:flex-col md:items-center'>
    <div className='flex-1 flex-col md:w-full md:mx-auto md:pb-12'>
        <p className='font-["Sprinkles"] text-[42px] sm:text-[36px] mb-8'>our process</p>
        <div className='flex flex-col w-[50%] md:w-full sm:text-sm gap-4 font-bold text-md mb-8'>
            <p className='bg-green-100 px-4 py-2 rounded-md '>results in weeks ,  not months</p>
            <p className='bg-green-100 px-4 py-2 rounded-md '>Onboard in 24 hours</p>
            <p className='bg-green-100 px-4 py-2 rounded-md '>Designs in 48 hours</p>
        </div>

        <Link to={'/bookCall'}>
        <button className='bg-cinnemon px-6 py-3 rounded-lg font-bold hover:scale-105 transition-transform'>Start a project <FaArrowRightLong className='inline-block ml-2'/></button>
        </Link>
        
    </div>
    <div className='flex-1'>
    <ProcessStepsUi number={1} heading={"Onboard"} text={"We move fast. Every project starts with understanding your company's industry and ambitions so we can tailor our solution to you."}/>
    <ProcessStepsUi number={2} heading={"Ideate"} text={"We start our creative process through research, moodboarding and exploration."}/>
    <ProcessStepsUi number={3} heading={"Iterate"} text={"Those ideas are turned into 3 design iterations that we present to you."}/>
    <ProcessStepsUi number={4} heading={"Refine"} text={"Based on your feedback we refine your brand / site into the finished designs."}/>
    <ProcessStepsUi number={5} heading={"Scale"} text={"Our development team put our design work into action building a scalable, high-converting website that is maintainable by your team."}/>
    </div>
       
    </div>
  )
}


const ProcessStepsUi =({number,heading,text}) =>{
    
    return(
        <div className='w-[75%] md:w-full'>
            <div className='flex gap-14'>
                <h1 className='text-[2rem] text-gray-600'>{number}</h1>
                <h2 className='font-["Bright"] text-[2.5rem] sm:text-[1.8rem]'>{heading}</h2>
            </div>
            <div className='border-l-2 border-green-100 rounded-sm flex pb-8 mb-4'>
              <p  className='pl-[4.5rem] sm:text-sm'>
              {text}
              </p>
               
            </div>
        </div>
    )
}
export default Process