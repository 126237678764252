import React from 'react'
import Blogs from './Blogs'
import BlogPageOutline from './BlogPageOutline/BlogPageOutline';


const BlogUi = () => {
   

  return (
        <div className='flex 2xl:flex-col-reverse w-full '>
         <BlogPageOutline />
         <div className='w-[60%] 2xl:w-[100%]'>
            <Blogs/>
         </div>
      </div> 
    
    
  );
}


export default BlogUi