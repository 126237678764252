import React from 'react'

const TestimonialCard = ({testimonial}) => {
    console.log(testimonial)
  return (
    <div className='w-[50%] lg:w-full lg:mx-8 3sm:mx-0'>
        <section class="bg-cinnemon rounded-3xl">
  <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:w-full">
      <figure class="max-w-screen-md mx-auto">
          <svg class="h-12 xl:h-10 mx-auto mb-3 text-white" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
          </svg> 
          <blockquote>
              <p class="text-2xl xl:text-xl lg:text-lg md:text-md sm:text-sm font-medium text-gray-900 dark:text-white">{testimonial.desc}</p>
          </blockquote>
          <figcaption class="flex items-center justify-center mt-6 space-x-3 sm:flex-col text-center">
          <img class="w-20 h-20 xl:w-15 xl:h-15 rounded-full object-cover" src={testimonial.img} alt="" />
              {/* <img class="w-6 h-6 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png" alt="profile picture"> */}
              <div class="flex items-center divide-x-2 divide-white">
                  <div class="pr-3 font-medium text-gray-900 dark:text-white">{testimonial.name}</div>
                  <div class="pl-3 text-sm font-light text-white">{testimonial.title} at {testimonial.company}</div>
              </div>
          </figcaption>
      </figure>
  </div>
</section>
    </div>
  )
}

export default TestimonialCard