import React from 'react'
import AboutHero from '../page/About/AboutHero';
import AboutSection2 from '../page/About/AboutSection2';
import Cta from '../page/cta/Cta';
import Testimonial from '../page/testimonial/Testimonial';

const About = () => {
  return (
    <div className=''>
   
   <AboutHero />
   <AboutSection2 />
   <Testimonial/>
   <Cta />
   

    </div>
       
  )
}

export default About