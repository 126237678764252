import React from 'react'
import RecentPosts from './RecentPosts'

const RecentPostui = () => {
  return (
    <div className='w-full 2xl:flex flex-col items-center '>
    <h1 className='text-center text-cinnemon font-bold text-[56px] font-["Bright"] pt-12'>Recent Posts</h1>
    <div className='flex flex-col my-8 mx-12  px-2 2xl:bg-green-50 2xl:p-12'>
    <RecentPosts width={"100%"} />
    </div>
    </div>
  )
}

export default RecentPostui