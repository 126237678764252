import React from 'react'
import twoCreamFlower from '../../assets/twoCreamFlower.png'
import usePlaceFlower from '../../hooks/UsePlaceFlower'
import myImage from '../../assets/myImage2.png'
import { Link } from 'react-router-dom'

const About = () => {
     // Using the custom hook to place a flower
  const flowerElement1 = usePlaceFlower({ flower: twoCreamFlower, top: -220, left: -150, width: 230, height: 250 });

    return (
        <div className={` bg-primary-50 text-black relative h-full min-h-[600px]  py-28 px-[8rem] 3sm:px-6 w-full lg:py-12 lg:px-[4rem] lg:mb-12 `} >

        <div className='md:hidden'>
        {flowerElement1}
        </div>

        <div className='w-full flex items-center justify-between 2xl:flex-col 2xl:flex-col-reverse gap-12  '>
        <div className='text-center w-[800px] xl:w-full sm:w-full'>
         <h1 className='text-[48px] lg:text-[36px]  xl:text-[36px] sm:text-[28px] 3sm:text-[24px] 3sm:leading-[2rem] xl:leading-[2.5rem] leading-[4rem] text-cinnemon font-["Sprinkles"] '>You’re great at what you do.
Does your website tell that story?</h1>
        <p className='text-xl lg:text-lg md:text-md sm:text-sm text-green-300 font-bold'>Tired of blending in? Let's stand out together.</p>

        <div className='pt-10 font-bold leading-[1.75rem] flex flex-col items-center justify-center md:text-sm' >
        
        <p>Hii, I am your friend Deepali  :) .I empower startup & small business owners to boost sales, attract clients, and establish your expertise through a purposefully crafted website.</p>

        <p >As a web developer and designer, I craft captivating digital experiences that redefine the norm. From concept to launch, I empower startups with custom web solutions designed to leave a lasting impression.</p>
        <p>
        I'm passionate about creating seamless, user-friendly experiences that are both visually stunning and highly functional. I'm here to help you elevate your online presence and make a lasting impact.</p>
        <p>Ready to make your mark?</p>
        <p className='bg-cinnemon font-bold py-2 px-4 rounded-md w-fit mt-4 hover:scale-110 transition-transform shadow-2xl duration-500'>
          <Link to='/bookCall'>Let's talk</Link>
        </p>
        </div>
         </div>
       
        <div className='w-[400px] lg:w-[250px]'>
          <img src={myImage} alt="" className='w-full'  />
        </div>
        </div>
      
        
        
       </div>
    )
}

export default About