import React from 'react'
import Hero from '../page/Home/Hero'
import About from '../page/Home/About'
import Slider from '../page/slider/Slider'
import Whyme from '../page/Home/Whyme'
import Subscribe from '../page/subscribe/Subscribe'
import BlogSection from '../page/Home/BlogSection'
import Process from '../page/Home/Process'


const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <div className='relative h-0'>
      <Slider top={-50} rotate={5} color={'green-300'} zIndex={10}/>
      <Slider top={-150} rotate={-5} color={'pink-300'} zIndex={10}/>
      </div>
      <Whyme/>
      <Process/>
      {/* <Subscribe/> */}
      <BlogSection/>
    </div>
  )
}




export default Home