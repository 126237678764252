import React from 'react'
import UsePlaceFlower from '../../hooks/UsePlaceFlower'
import creamFlower from '../../assets/creamFlower.png'
import fflower from '../../assets/creamFlower.png'


const Slider = ({top,rotate,color}) => {

  return (
    <div className='md:hidden' style={{maxWidth:'98.5vw',width:'100%', position:'relative', top:`${top}px`,zIndex:'100px'}} >
    
    <div className={`overflow-hidden text-primary-50 flex items-center text-3xl font-bold  whitespace-nowrap  will-change-transform  bg-${color} `} style={{rotate:`${rotate}deg`,height:'100px',backgroundColor:`${color}`, top:`${top}px`}}>
    <p className='animate-slide inline-block'>Unleash your brand's potential ✨ Boost Engagement ✨ Achieve your goals ✨</p>
    <p className='animate-slide inline-block'>Unleash your brand's potential ✨ Boost Engagement ✨ Achieve your goals ✨</p>
    <p className='animate-slide inline-block'>Unleash your brand's potential ✨ Boost Engagement ✨ Achieve your goals ✨</p>
    </div>
   
    </div>
  )
}



export default Slider


