import React, { useState,useContext, useEffect } from 'react'
import BlogBanner from '../BlogBanner'
import PostsByCategory from './PostsByCategory'
import RecentPostsWithImg from '../recentPosts/RecentPostsWithImg';
import { AppContext } from '../../../context/AppContext';


const Category = () => {
    const [category,setCategory] = useState('');
    const {posts} = useContext(AppContext);
   
    useEffect(()=>{
       setCategory(posts[0]?.category_names.join(' , '));
    },[category])

  return (
    <div>
        <BlogBanner text={category}/>
        <div className='flex flex-col items-center justify-center flex-wrap sm:flex-nowrap px-12 '>
        <PostsByCategory posts={posts}/>
        <RecentPostsWithImg heading={"Recent Posts"}/>
        </div>
        
    </div>
  )
}

export default Category