const testimonials = [
    {
        id: 1,
        name: 'Meagen Coburn',
        title: 'Strategist',
        company:'Iydia writes good',
        desc: '"We love working with Deepali. She worked on our Webflow site , improving SEO, user experience and accessibility. Deepali provided us with tailored training videos on how to update and maintain the site ourselves and they are always on hand to help out. If you’re looking for a partner in Webflow design + development, look no further."',
        img: 'testimonialImages/meagan.webp',
    },
    {
        id: 2,
        name: 'Elsa Ramo',
        title: 'Founder',
        company:'pefanetwork',
        desc: '"Its is beautiful and interesting to work with Deepali, she provided a lot of insights on planning and them building our company website. and we also worked for couple of my clients websites. it has been all very smooth communication was very great and always available and helpful to guide us throughout the process. It was a nice experience working with her, I highly recommend her for any website design work!"',
        img: 'testimonialImages/elsa.jpg',
    },
    {
        id: 3,
        name: 'Michelle Wu',
        title: 'Founder',
        company: 'Michelle FOR MAYOR',
        desc: '"Deepali are as sharp and diligent as she get. She listen closely to understand the vision of the project and consistently offers ways to enhance the end product. She has  incredibly responsive and supportive team member who go above and beyond to deliver."',
        img: 'testimonialImages/michelle.webp',
    },

]

export default testimonials