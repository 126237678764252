import React from 'react'
import CategoryNameDisplay from '../category/CategoryNameDisplay'
import RecentPostsUi from '../recentPosts/RecentPostui'


const BlogPageOutline = () => {
  return (
    <div className='w-[40%] 2xl:w-[100%] '>
    <CategoryNameDisplay />
    <RecentPostsUi />
  </div>
  )
}

export default BlogPageOutline