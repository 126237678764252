import React from 'react'
import { FaFacebook, FaGithub , FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';

const Footer = () => {

  return (
    <div className='bg-skinnyPink-100 w-full h-full px-16 pt-16 pb-6 flex flex-col '>
<div className=' flex  justify-evenly lg:flex-col lg:gap-6'>
    <section className='lg:mb-4 '>
    <h1 className='pb-8 lg:pb-4 sm:pb-0  text-5xl sm:text-3xl text-cinnemon font-["Sprinkles"] lg:text-center'>Quick Links</h1>
    <ul className='text-center flex flex-col lg:flex-row items-center justify-center lg:gap-8 md:gap-4  3sm:flex-col'>
        <li className='text-lg md:text-md sm:text-sm font-semibold text-green-300 hover:text-green-300/50 transition-opacity'> <NavLink to="/">Home</NavLink></li>
        <li className='text-lg md:text-md sm:text-sm font-semibold text-green-300 hover:text-green-300/50 transition-opacity'> <NavLink to={"/about"}>About</NavLink></li>
        <li className='text-lg md:text-md sm:text-sm font-semibold text-green-300 hover:text-green-300/50 transition-opacity'> <NavLink to={"/projects"}>Projects</NavLink></li>
        <li className='text-lg md:text-md sm:text-sm font-semibold text-green-300 hover:text-green-300/50 transition-opacity'> <NavLink to={"/blogs"}>Blogs</NavLink></li>
      </ul>
    </section>
     

     <section>
        <h1 className='text-center pb-8 sm:pb-0 text-5xl sm:text-3xl text-green-200 font-["Sprinkles"]'>Follow me</h1>
        <ul className='flex items-center justify-center gap-8 lg:gap-4  3sm:flex-col'>
        {/* <li><a href="https://www.facebook.com/profile.php?id=61556224094977" target="blank"  className='text-cinnemon text-4xl sm:text-3xl  hover:text-cinnemon/50 transition-opacity'><FaFacebook/></a></li> */}
        <li><a href="https://www.instagram.com/developergirl123" target="blank"  className='text-cinnemon text-4xl  sm:text-3xl hover:text-cinnemon/50 transition-opacity'><FaInstagramSquare/></a></li>
        {/* <li><a href="https://www.linkedin.com/in/deepali-lodhi-34b3912ab/" target="blank"  className='text-cinnemon text-4xl  sm:text-3xl hover:text-cinnemon/50 transition-opacity'><FaLinkedin/></a></li> */}
        <li><a href="https://twitter.com/meDeepalilodhi" target="blank"  className='text-cinnemon text-4xl sm:text-3xl  hover:text-cinnemon/50 transition-opacity'><FaXTwitter /></a></li>
        {/* <li><a href="https://github.com/Deepali-1508"  target="blank" className='text-cinnemon text-4xl sm:text-3xl  hover:text-cinnemon/50 transition-opacity'><FaGithub/></a></li> */}
        {/* <li><Link to="/#subscribe" className='text-cinnemon text-4xl hover:text-cinnemon/50 transition-opacity'><a href="/#subscribe"><MdUnsubscribe/></a></Link></li> */}
      </ul>
     </section>
     
    </div>

    <div className='text-center border-t-2 pt-4 border-green-200/50 font-bold text-green-100'>
      <h1>deepali.tech Copyright ©2024 All rights reserved.</h1>
    </div>
    </div>
    
  )
}

export default Footer