import React from 'react'
import { Link } from 'react-router-dom'

const MiniHeader = () => {
  return (
    <div className='w-full h-full p-5 text-center font-bold bg-pink-300'>
      <h1 className='text-primary-100'>
            " Websites that Wow & Work : Unleash Your Brand's Potential 
            with My Front-End Expertise. "
      </h1>
      <button style={{ textDecoration: 'underline', color: '#362425', border: 'none', background: 'none', cursor: 'pointer' }}>
        <Link to='/bookCall'>Book a call</Link>
      </button>
    </div>
  )
}

export default MiniHeader



