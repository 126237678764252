import React, { useEffect, useState } from 'react'


const WhymeUi = ({odd,keepFirstOpen,content,h1,b1,h2,b2,h3,b3,h4,b4}) => {

    const [visibleIndex , setVisibleIndex] = useState(-1);
   
    const toggleVisibility = (index) =>{
        setVisibleIndex(-1);
       setVisibleIndex(visibleIndex === index ? -1 :index);
    }

     useEffect(() => {
        if(keepFirstOpen){
            setVisibleIndex(0);
        }
    }
    , [])
    
  return (
    <div className={`flex items-center justify-between px-[10rem] sm:px-8 lg:px-[4rem] mt-16 gap-16 lg:gap-12 ${odd === true ?'flex-row-reverse':'flex-row'} xl:flex-col` }>
        
            <p className='flex-1 text-lg font-semibold lg:text-md md:text-sm '>
            {content}
            </p>
        
        <div className='flex-1 cursor-pointer'>
           <div className={` ${visibleIndex === 0 ? 'bg-green-200/50 p-8 text-primary-50 rounded-lg ' : 'cursor-pointer'}`}>
                <h1 onClick={() => toggleVisibility(0)} className={`text-[42px] md:text-[18px]   lg:text-[24px]  2xl:text-[36px]  font-extrabold ${visibleIndex === 0 ? 'border-b-0' : ' border-b-2  border-green-200/50'}`}>{h1}</h1>
                {visibleIndex === 0 && <p>{b1}</p>}
            </div>
            <div className={`cursor-pointer ${visibleIndex === 1 ? 'bg-green-200/50 p-8 text-primary-50 rounded-lg ' : ''}`}>
                <h1 onClick={() => toggleVisibility(1)} className={`text-[42px] md:text-[18px]  lg:text-[24px] 2xl:text-[36px]  font-extrabold ${visibleIndex === 1 ? 'border-b-0' : ' border-b-2 border-green-200/50'}`}>{h2}</h1>
                {visibleIndex === 1 && <p>{b2}</p>}
            </div>
            <div className={` ${visibleIndex === 2 ? 'bg-green-200/50 p-8 text-primary-50 rounded-lg ' : ''}`}>
                <h1 onClick={() => toggleVisibility(2)} className={`text-[42px] md:text-[18px]  lg:text-[24px] 2xl:text-[36px]  font-extrabold ${visibleIndex === 2 ? 'border-b-0' : ' border-b-2 border-green-200/50'}`}>{h3}</h1>
                {visibleIndex === 2 && <p>{b3}</p>}
            </div>
             <div className={` ${visibleIndex === 3 ? 'bg-green-200/50 p-8 text-primary-50 rounded-lg ' : ''}`}>
                <h1 onClick={() => toggleVisibility(3)} className={`text-[42px] md:text-[18px]  lg:text-[24px] 2xl:text-[36px]  font-extrabold ${visibleIndex === 3 ? 'border-b-0' : ' border-b-2 border-green-200/50'}`}>{h4}</h1>
                {visibleIndex === 3 && <p>{b4}</p>}
            </div>
            
        </div>
        
    </div>
  )
}

const OpenBox = ({heading , body}) => {
    const [isOpen ,setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div>
            <h1 onClick={handleOpen}>{heading}</h1>
            <p className={`${isOpen ? 'flex' : 'hidden'}`}>
           {body}
            </p>
        </div>
    )
}
export default WhymeUi