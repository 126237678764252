import React from 'react';
import Post from '../Post';

function PostsByCategory({posts}) {
  
  return (
    <div className='w-full flex items-center flex-wrap gap-12 justify-center mb-12'>
      {posts.map(post => (
        <div key={post.id}>
          <Post post={post} img={true}/>
        </div>
      ))}
    </div>
  );
}



export default PostsByCategory;
