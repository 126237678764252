import React from 'react'
import RecentPostsWithImg from '../Blog/recentPosts/RecentPostsWithImg'

const BlogSection = () => {
  return (
    <div className='bg-primary-200 w-full bg-center bg-cover p-16'>
    <h1 className='font-semibold font-["Sprinkles"] text-[64px] sm:text-[36px] text-pink-400 text-center'>On the <span className='text-green-200 text-[64px] sm:text-[36px] font-thin font-["Bright"]'>Blog</span></h1>
     <RecentPostsWithImg/>
    </div>
  )
}

const BlogUi = () =>{
    return(
        <div>
          
        </div>
    )
}
export default BlogSection