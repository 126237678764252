import React, { useContext } from 'react'
import Loader from '../Loader/Loader'
import Post from './Post'
import { AppContext } from '../../context/AppContext'

const Blogs = () => {
    
   const { posts , loading} = useContext(AppContext);
    
  return (
    <div className='flex flex-wrap items-center justify-start 3xl:justify-center w-full  gap-12 pb-16'>
        {
            loading ? <Loader/> :
            
             Object.keys(posts).length ? 
             
             posts.map(post =>{
                return(
                   
                    <Post key={post.id} post={post} img={true}/>
                )
            }):

            <div>
                <p>No posts</p>
            </div>
        }
    </div>
  )
}

export default Blogs