import React from 'react'
import WhymeUi from './WhymeUi'


const Whyme = () => {
  return (
    <div className='bg-green-100  h-[full] min-h-[600px] w-full bg-cover py-28 mt-12 text-center'>
     <h1 className='text-[64px] lg:text-[36px] sm:text-[28px] font-extrabold font-["Bright"]'>Here's How You Benefit</h1>

     <WhymeUi
        odd={false}
        keepFirstOpen={true}
        content={" Your website is the digital face of your business. It's the first impression you make on potential customers and the foundation of your online presence. With my expertise, we can create a website that captivates your audience, drives conversions, and sets you apart from the competition. Here's why we're the perfect match:"}
        h1={'Branding & Strategy'} b1={"Let's start by exploring your website's purpose and audience. We'll discuss your goals, pain points, and who your users are , your competition. This way, we can ensure every aspect of your site is tailored to meet their needs, setting the stage for an engaging online experience. "}
        h2={'Design'} b2={"Now, it's time to infuse your website with personality and charm. We'll collaborate closely to capture the essence of your brand, translating it into captivating Figma mockups.We refine these drafts until they perfectly reflect the flow and functionality your users deserve."}
        h3={'Development'} b3={"As we bring your design to life, we prioritize user experience and ease of maintenance. From captivating animations to seamless navigation, every detail is meticulously crafted. We'll also install essential tools like Google Analytics and perform SEO optimization, ensuring your site is primed for success from the moment it goes live."}
        h4={'Website Maintenance'} b4={"After your site launches, our maintenance plan keeps it secure and updated. We handle the technical stuff, so you can focus on growing your business. Plus, we'll analyze your Google Analytics and send you monthly reports detailing site updates, security measures, and key user metrics like page views and site traffic. "}
     />

    <WhymeUi
        odd={true}
        content={" Whether it's driving sales, generating leads, or simply sharing your passion, I craft websites that deliver measurable results.I believe in the power of websites to transform businesses and connect with people. "}
        h1={'Innovative Solutions'} b1={' I thrive on finding inventive solutions to your digital challenges.  '}
        h2={'Responsive Designs'} b2={'Your website will adapt seamlessly to any screen size, ensuring a consistent experience for every visitor.'}
        h3={'Commitment to Excellence'} b3={"  I don't settle for good, I aim for exceptional. Your website will be a testament to my commitment to excellence."}
        h4={'Data-driven approach'} b4={' I track results and optimize the user journey for continuous improvement. Ready to elevate your online presence and unlock the true potential of your website.'}
     />
    </div>
  )
}

export default Whyme