import React from 'react'
import creamFlower from '../../assets/creamFlower.png'

const Hero = () => {
  return (
    <div className=' w-full h-full min-h-[600px] md:min-h-[400px] md:static  lg:min-h-[500px] relative bg-center bg-sideRainbow  px-16 py-16 3sm:px-4 flex items-center justify-center flex-col '>
        <div className='absolute left-0 top-1'>
         <img src={creamFlower} alt="flower" width={300} className='lg:w-[200px] md:hidden'/>
        </div>
        
        <div className=' z-40 flex  flex-col md:static absolute top-[13rem] items-center text-center leading-[3.2rem] 2xl:leading-[4rem] lg:leading-[2.5rem] 3sm:leading-[1.8rem]'>
        
        <p className='font-["Bright"] font-bold text-[64px] 2sm:text-[28px] 3sm:text-[24px] md:text-[30px] lg:text-[36px] 2xl:text-[56px] text-green-300 mb-4 '>"We bring your brand into focus"</p>
        <p className='text-[24px] font-bold md:text-[26px] lg:text-[32px] 2xl:text-[46px] text-cinnemon 2sm:text-[24px] 3sm:text-[20px]'>"We believe in providing value"</p>
        


        <h1 className='text-lg md:text-[16px] font-bold text-pink-300 pt-5 w-[500px] md:w-full 2sm:text-[14px]'>A freelance <span className='uppercase  md:text-[18px] sm:text-[16px]' >Web developer & web designer</span>  propelling startup & small business visions to reality.</h1>
        
        
        </div>

 </div>
  )
}

export default Hero