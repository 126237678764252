import React from 'react'
import categoryImageData from './categoryImageData'

const CategoryNameDisplayUi = ({category}) => {
  let categoryImg = ''
  if(categoryImageData[category]){
    categoryImg = categoryImageData[category]
  }

  return (
    <div className='bg-center bg-no-repeat  bg-green-300 bg-cover h-[100px] my-4 mx-12 flex items-center justify-center' style={{backgroundImage:`url(${categoryImg})`}}>
        <h1 className='text-white font-bold text-4xl 2sm:text-2xl'>{category}</h1>
    </div>
  )
}

export default CategoryNameDisplayUi