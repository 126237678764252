import React,{useEffect} from 'react';
import { Routes ,Route, useLocation } from 'react-router-dom';

import Home from './components/Home';
import Blog from './components/Blog';
import Header from './components/Header';
import MiniHeader from './components/MiniHeader';
import Footer from './components/Footer';
import About from './components/About'
import Project from './components/Project';
import FullPostBlogOutline from './page/Blog/FullBlogPost/FullPostBlogOutline';
import Category from './page/Blog/category/Category';

import { useContext } from 'react';
import { AppContext } from './context/AppContext';
import Calendly from './page/calendly/Calendly';

function App() {
   const {fetchBlogPosts,fetchRecentPosts} = useContext(AppContext);
   const location = useLocation();

   useEffect(()=>{
      if(location.pathname.includes("category")){
        const category = location.pathname.split("/").at(-1);
        // console.log("location.pathname.inclues",category);
        fetchBlogPosts(category);
      }
      else{
        fetchBlogPosts();
       
      }
      fetchRecentPosts();
   },[location.pathname])

  return (
    <div className='w-full h-full text-textColor bg-primary-100 '>
    <MiniHeader />
    <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/projects' element={<Project/>} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog/:id' element={<FullPostBlogOutline/>} />
        <Route path='/category/:id' element={<Category/>} />
        <Route path='/bookCall' element={<Calendly/>} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
