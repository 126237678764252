import React,{useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom'
import nameLogo from '../assets/DeepaliRetro2.png'
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaTwitter,FaGithub,FaHamburger ,FaTimes } from "react-icons/fa";
import MobileNav from './MobileNav';


const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(()=>{
    const handleResize = () => {
      if(window.innerWidth > 965){
        setMobileMenu(false)
      }
    }

    window.addEventListener('resize',handleResize)

    return () => {
      window.removeEventListener('resize',handleResize)
    }

  },[ ])

  return (
    <>
    <div className={`w-full h-full bg-primary-50 flex 2sm:flex-col 2sm:gap-4 items-center justify-evenly md:justify-between py-8 px-16 text-green-300 ${mobileMenu? 'fixed top-[-15rem] left-0 z-50 w-full h-full':''}`}>
        
        {/* mobile nav */}
        <div className='items-center hidden xl:flex' onClick={()=>setMobileMenu(!mobileMenu )}>
        {mobileMenu ? (
          <FaTimes className="text-[52px] lg:text-[48px] md:text-[36px]" />
        ) : (
          <FaHamburger className="text-[52px] lg:text-[48px] md:text-[36px]" />
        )}
        </div>


      


      {/* desktop nav */}
        <ul className='flex items-center justify-between gap-5  xl:hidden'>
            <li className='hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold  cursor-pointer'><NavLink to={'/'}>Home</NavLink></li>
            <li className='hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold  cursor-pointer'><NavLink to={'/about'}>About</NavLink></li>
            <li className='hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold  cursor-pointer'><NavLink to={'/blog'}>Blog</NavLink></li>
            <li className='hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold  cursor-pointer'><NavLink to={'/projects'}>Work</NavLink></li>
        </ul>

       
        <NavLink to={'/'} className=' flex items-center justify-center cursor-pointer'>
           <img src={nameLogo} alt='nameLogo' className=' w-[50%] min-w-[250px] md:min-w-[100px] animate-ping'/>
        </NavLink>
           
        

        <ul className='flex items-center justify-between gap-5 text-cinnemon  xl:hidden'>
           {/* <li><a href="https://www.linkedin.com/in/deepali-lodhi-34b3912ab/" target="blank" ><FaLinkedin fontSize={30} className='hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in'/></a></li> */}
           <li><a href="https://twitter.com/meDeepalilodhi" target="blank" ><FaTwitter  fontSize={30} className='hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in'/></a></li>
           <li><a href="https://www.instagram.com/developergirl123" target="blank" ><FaInstagramSquare fontSize={30} className='hover:text-green-300  cursor-pointer transition-{color} duration-200 ease-in'/></a></li>
           {/* <li><a href="https://www.facebook.com/profile.php?id=61556224094977" target="blank"><FaFacebook fontSize={30} className='hover:text-green-300  cursor-pointer transition-{color} duration-200 ease-in'/></a></li> */}
           {/* <li><a href="https://github.com/Deepali-1508"  target="blank" className='hover:text-green-300  cursor-pointer transition-{color} duration-200 ease-in'><FaGithub fontSize={30} /></a></li> */}
           <li className='hover:bg-green-300 text-white bg-cinnemon p-2 rounded-md font-semibold cursor-pointer transition-{color} duration-200 ease-in'><NavLink to={'/bookCall'}>Contact</NavLink></li>
        </ul>

       {/* mobile nav */}
       <div className='hidden md:text-sm hover:text-green-300 text-white bg-cinnemon p-2 rounded-md font-semibold cursor-pointer transition-{color} duration-200 ease-in xl:flex'><NavLink to={'/contact'}>Contact</NavLink></div>
   
     </div>
       {mobileMenu && (
        <div className="fixed inset-0 bg-primary-100 z-50 flex flex-col items-center justify-center mt-[8rem] ">
          <MobileNav closeMenu={() => setMobileMenu(false)} />
        </div>
      )}
      </>
  )
}

export default Header