import React from 'react'

const ProjectUi = ({projects}) => {
  return (
    <div  className='py-8 px-16 mt-12   w-full h-full flex item-center justify-center flex-wrap gap-4  '>
     {
     //check if projects is not empty
      projects.length === 0 ? <h1 className='h-[50vh] flex items-center justify-center text-[36px] text-cinnemon'>Projects will get updated soon</h1> :
      projects.map((project,index)=>(
        <div className='w-[450px] h-[450px] overflow-hidden shadow-xl'>
          <a href={project.demoUrl}  target="blank">
            <img src={project.imgSrc} alt={project.title} 
            className='w-full h-full object-cover hover:object-bottom object-top transition-{object-top} duration-1000 ease-in delay-1000'
             />
          </a>
        </div>
      ))
     }
       
    </div>
  )
}

export default ProjectUi