import React from 'react'

const UsePlaceFlower = ({ flower, top, bottom, left, right, width, height }) => {
    return (
        <div className='relative'>
          <img
            src={flower}
            alt="flower"
            width={width}
            height={height}
            style={{
              position: 'absolute',
              zIndex: 10,
              top: `${top}px`,
              bottom: `${bottom}px`,
              left: `${left}px`,
              right: `${right}px`,
            }}
          />
        </div>
      );
    };
export default UsePlaceFlower