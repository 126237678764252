import React from 'react'
import FullPostBlog from './FullPostBlog'
import RecentPostsWithImg from '../recentPosts/RecentPostsWithImg'

import creamFlower from '../../../assets/creamFlower.png'
import  cinnemonFlower from '../../../assets/cinnemonFlower.png'

const FullPostBlogOutline = () => {
  return (
    <div className=' w-full relative'>
       <img src={creamFlower} alt="whiteflower" className='z-30 3xl:hidden w-[100px] absolute top-[3rem] left-[6rem]'/>
       <img src={creamFlower} alt="whiteflower" className='z-30 3xl:hidden w-[100px] absolute top-[3rem] right-[6rem]'/>
       <FullPostBlog/>
       <RecentPostsWithImg heading={"Recent Posts"}/>
    </div> 
  )
}

export default FullPostBlogOutline