import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { FaFacebook,FaTwitter,FaLinkedin,FaInstagramSquare,FaGithub } from 'react-icons/fa';

const MobileNav = ({ closeMenu }) => {

    //if its width goes more than 965px then it will close the menu 
    useEffect(()=>{
        const handleResize = () => {
            if(window.innerWidth > 965){
                closeMenu()
            }
        }

        window.addEventListener('resize',handleResize)

        return () => {
            window.removeEventListener('resize',handleResize)
        }

    },[])

  return (
    <div className={`flex flex-col items-center justify-start pt-8 w-screen h-screen`}>
      <ul className="flex flex-col items-center justify-center gap-5 text-green-300">
        <li className="hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold cursor-pointer">
          <NavLink to={'/'} onClick={closeMenu}>
            Home
          </NavLink>
        </li>
        <li className="hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold cursor-pointer">
          <NavLink to={'/about'} onClick={closeMenu}>
            About
          </NavLink>
        </li>
        <li className="hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold cursor-pointer">
          <NavLink to={'/blog'} onClick={closeMenu}>
            Blog
          </NavLink>
        </li>
        <li className="hover:text-cinnemon transition-{color} duration-200 ease-in font-semibold cursor-pointer">
          <NavLink to={'/projects'} onClick={closeMenu}>
            Work
          </NavLink>
        </li>
      </ul>

      <ul className="flex items-center justify-center gap-5 text-cinnemon pt-8">
        {/* <li>
          <a href="https://www.linkedin.com/in/deepali-lodhi-34b3912ab/" target="blank">
            <FaLinkedin fontSize={30} className="hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in" />
          </a>
        </li> */}
        <li>
          <a href="https://twitter.com/meDeepalilodhi" target="blank">
            <FaTwitter fontSize={30} className="hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/developergirl123" target="blank">
            <FaInstagramSquare fontSize={30} className="hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in" />
          </a>
        </li>
        {/* <li>
          <a href="https://www.facebook.com/profile.php?id=61556224094977" target="blank">
            <FaFacebook fontSize={30} className="hover:text-green-300 cursor-pointer transition-{color} duration-200 ease-in" />
          </a>
        </li> */}
        {/* <li><a href="https://github.com/Deepali-1508"  target="blank" className='hover:text-green-300  cursor-pointer transition-{color} duration-200 ease-in'><FaGithub fontSize={30}/></a></li> */}
      </ul>

      
    </div>
  );
};

export default MobileNav;
