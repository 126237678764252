import React from 'react'

const AboutSection2 = () => {
  return (
    <div className='bg-green-200 text-center 2sm:px-4 px-16 py-36 flex items-center justify-center flex-col'>
    <h1 className='font-["Sprinkles"] text-[83px] xl:text-[64px] md:text-[56px] sm:text-[48px] text-skinnyPink-100 pb-8'>Design - Build - Market</h1>
    <p className='text-white w-[50%] 3sm:w-full text-center xl:w-[500px] md:text-sm md:w-full md:px-8'>It's our job to transform your brand into its best digital self. We are driven by a customer centric approach in creating engaging, interactive and immersive experiences that deliver only the best.</p>
    </div>
  )
}

export default AboutSection2

