import React from 'react'
import RecentPosts from './RecentPosts'

const RecentPostsWithImg = ({heading}) => {
  return (
    <div>
    {
        heading && <h1 className='text-center text-cinnemon font-bold text-[56px] font-["Bright"] pt-12 sm:text-[36px]'>{heading}</h1>
    }
    <div className='flex flex-wrap 2sm:flex-nowrap 2sm:flex-col   gap-8 items-center justify-center py-8  px-2'>
    <RecentPosts img={true} width={'400px'}/>
    </div>
    </div>
  )
}

export default RecentPostsWithImg