import React from 'react'

const BlogBanner = ({text}) => {
  return (
    <div className='w-full h-[250px] font-light flex flex-col items-center justify-center bg-skinnyPink-200 mb-12'>
        <h1 className='text-cinnemon text-[64px] sm:text-[42px] font-["Sprinkles"]'>on the blog</h1>
        <p className='text-green-200 font-bold capitalize'>{text}</p>
    </div>
  )
}

export default BlogBanner