import React from 'react'
import myImage from '../../assets/myImage.png'

const AboutHero = () => {
  return (
    <div className='bg-green-50 flex items-center justify-center gap-28 md:gap-16 py-12 3xl:px-8 xl:flex-col xl:flex-col-reverse'>
   <div className='w-[600px] md:w-full text-center '>
       
       <h1 className='font-["Sprinkles"] text-[56px] md:text-[36px] text-cinnemon'>Hii, I am Deepali Lodhi. </h1>
       <h1 className='text-md font-bold text-green-300 mb-8'>A web designer and developer based in India, working globally! </h1>

       {/* <p className='text-textcolor md:text-sm font-["poppins"] font-semibold'>
         My story begins when it was my last year in college when i realized like everyone else that i need to do something in life . So as a computer science student and very fascinated towards technology . I started coding . I am not a college dude , i usually don't go to college I mean why not.So i started learning my own through documentation, youtube , courses . I learned python, c/c++ and then finallly WEB DEVELOPMENT and I became frontend developer .And i landed up on a 3 months internship (two character asking stipend , the one said these thing not to share).
       </p> */}
       <p className='text-textcolor text-[24px] md:text-sm font-["Bright"] font-semibold'>I ain't only about websites!</p>
       <p className='text-textcolor md:text-sm font-["poppins"] font-semibold '>
       I may be a professional and expert at work but that doesn’t mean I am not fun, casual, and open to discuss about how your doggo destroyed your pillow or shoes. I am all ears about your everyday business and life struggles, in the end, we are all human and we connect that way.
       </p>
       <p className='text-textcolor md:text-sm font-["poppins"] font-semibold mt-8'>Besides working with you and talking about the project you may expect me to ask about your story, your struggles, and whereabout of you and life. I love to understand the person I am working with deeply. I love to meet new people and get to know them, their story and how did they start!</p>
       <p className='text-textcolor md:text-sm font-["poppins"] font-semibold mt-8'>
        <mark className='bg-green-100'>  I am not limited to just building you a website that does its job, I will always be there to support you at any point of your journey with my help, expertise and network (I know a bunch of other cool freelancers). I can be your friend, mentor, guide, helping hand and partner in success. My past clients are still my friends and constantly get in touch, they still ask me for help and suggestions. I am always happy to listen and respect your suggestions and opinions.</mark>
        </p>
       <p className='text-textcolor md:text-sm font-["poppins"] font-semibold mt-8'>
        I am also a small business owner running my web design business just like you. At first, I  didn’t know how to start an online business but I was desperate to succeed and earn an income online. I did so many things like blogging, SEO, affiliate marketing, etc (failed miserably) and eventually realized how much I enjoyed building websites while I built my own for all these stuff.</p>

      </div>

      <img src={myImage} alt="author" width={500} className='3xl:w-[400px] md:w-[250px]' />
   </div>
  )
}

export default AboutHero