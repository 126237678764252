import React, {useState, useEffect} from 'react'
import { InlineWidget } from "react-calendly";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsCopy } from "react-icons/bs";
import testimonials from '../testimonial/testimonials';
import TestimonialCard from '../testimonial/TestimonialCard';


const Calendly = () => {
  const [testimonial, setTestimonial] = useState(testimonials[0]);
  const [text, setText] = useState('hello@deepali.tech');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setText('hello@deepali.tech')
        setCopied(false);
      }, 2000); // 5 seconds

      // Cleanup the timeout if the component unmounts or copied changes
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <div className='w-full'>
    <div className='flex items-center justify-center flex-col w-full mt-10'>
    <h1 className='font-bold text-[36px] sm:text-[20px] text-green-300 '>Book a call</h1>
       <p className='text-cinnemon font-bold text-center sm:text-sm'>Schedule a time to discuss your project</p>
      
    </div>

    {/* <InlineWidget url="https://calendly.com/deepalilodhi15/30min?background_color=a1c0a9&text_color=ffffff&primary_color=518681" styles={{
  height: '900px',
  marginTop: '50px',
  marginBottom: '50px',
//   backgroundColor:'#518681',
}} /> */}

<InlineWidget url="https://calendly.com/hello-lpo1/30min?text_color=black&primary_color=d39320" styles={{
  height: '900px',
  marginTop: '50px',
  marginBottom: '50px',
  // backgroundColor:'#518681',
}} />



<div className='flex items-center justify-center flex-col mb-12'>
<h1 className='font-bold text-green-300 text-[36px] md:text-[28px] sm:text-[16px] text-center font-["Bright"]'>Prefer to send an email?</h1>

<CopyToClipboard text={text} onCopy={() => setCopied(true)} className="flex gap-2 bg-green-300 p-4 rounded-xl shadow-2xl font-bold text-white sm:text-sm 2sm:text-[10px] mb-14">
        <button>{copied ? 'copied' : 'HELLO@DEEPALI.TECH'} <BsCopy className='translate-y-1'/> </button>
</CopyToClipboard>
 

<TestimonialCard testimonial={testimonial} />

</div>

    
    </div>
  )
}

export default Calendly
