import React from 'react'
import BlogBanner from '../page/Blog/BlogBanner'
import BlogUi from '../page/Blog/BlogUi'
import Cta from '../page/cta/Cta'

const Blog = () => {

  return (
    <div>
      <BlogBanner text={"tech, thoughts, stories ,books and ideas"}/>
      <BlogUi/>
      <Cta/>
    </div>
  )
}

export default Blog