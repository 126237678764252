import React from 'react'
import { Link } from 'react-router-dom'

const Post = ({post,img}) => {
//  console.log(post)
  return (
    <div key={post.id} className=' w-[400px] 2sm:w-[300px] 3sm:w-[200px] flex flex-col  '>
    <Link to={`/blog/${post.id}`} className="space-y-5">
     {img && <img src={post.featured_src ? post.featured_src : 'https://via.placeholder.com/500'} alt={post.title.rendered} className="w-full h-64 object-cover " />}
      <h2 className='text-cinnemon  text-lg font-bold line-clamp-1'>{post.title.rendered}</h2>
      <p className='text-green-300 line-clamp-3' dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
    </Link>
  </div>
  )
}

export default Post
