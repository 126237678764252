import React, { useState } from 'react'
import testimonials from './testimonials'
import TestimonialCard from './TestimonialCard';
import { FaChevronLeft , FaChevronRight } from "react-icons/fa";


const Testimonial = () => {
    const [index,setIndex] = useState(0);
     
    const handleLeft = () => {
        setIndex((index) => {
            let newIndex = index - 1;
            if(newIndex < 0){
                newIndex = testimonials.length - 1;
            }
            return newIndex;
        })
    }

    const handleRight = () => {
        setIndex((index) => {
            let newIndex = index + 1;
            if(newIndex > testimonials.length - 1){
                newIndex = 0;
            }
            return newIndex;
        })
    }

  return (
    <div className='px-16 lg:px-10 sm:px-2 py-16 bg-green-50 text-center md:pb-20'>
        <h1 className='text-center mb-8 font-bold font-["Sprinkles"] text-[46px] md:text-[32px] text-green-200'>What My Client Say's</h1>
        {/* build testimonial slider here */}
        <div className='flex justify-between md:relative'>
        <button onClick={handleRight} className='md:absolute bottom-[-4rem] left-[10rem] 2sm:left-[5rem]'><FaChevronLeft  className='text-[3rem] text-cinnemon hover:scale-110 transition-transform duration-400'/></button>
        <TestimonialCard testimonial={testimonials[index]} />
        <button onClick={handleLeft} className='md:absolute bottom-[-4rem] right-[10rem] 2sm:right-[5rem]'><FaChevronRight className='text-[3rem] text-cinnemon hover:scale-110 transition-transform duration-400 '/></button>
        </div>
        
    </div>
  )
}

export default Testimonial


