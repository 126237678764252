import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowRightLong } from "react-icons/fa6";

const Cta = () => {
  return (
    <div className=' w-full p-20 sm:px-2 bg-green-100 shadow-xl flex items-center justify-center  flex-col gap-4'>
<h1 className='text-[56px] lg:text-[48px] md:text-[36px] sm:text-[28px] text-center font-bold font-["Bright"] text-green-200'>Ready to build together</h1>
<p className='w-[50%] md:w-full text-center font-bold text-white'>From world-class brands, high-converting designs and lightening fast  sites to white-glove migrations. We can help you build better, move faster and scale your business</p>
<Link to='/bookCall'>
<button className='bg-cinnemon p-2 rounded-md w-fit mt-4 hover:scale-110 transition-transform	 text-white font-bold duration-500'>
Start a project <FaArrowRightLong className='inline-block ml-2'/>
</button>
</Link>
    </div>
  )
}

export default Cta
// bg-gradient-linear from-purple-600 via-pink-600 to-blue-600
// bg-[linear-gradient(90deg, rgba(81,134,129,1) 0%, rgba(248,220,206,1) 40%, rgba(248,220,206,1) 56%, rgba(211,147,32,1) 100%)]