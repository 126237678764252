import React from 'react'
import ProjectsData from '../json/ProjectsData'
import ProjectUi from '../page/Projects/ProjectUi'
import Cta from '../page/cta/Cta'

const Project = () => {
  const [projects, setProjects] = React.useState(ProjectsData)
  

  return (
    <div className='bg-primary-100 pt-12'>
    <div className='flex items-center justify-center flex-col gap-6'>
    <h1 className='text-[42px] font-bold font-["Sprinkles"] text-green-300 text-center'>Featured Projects</h1>
      {/* <p className='w-[50%] font-bold text-cinnemon 3sm:hidden'>Here's a selection of the websites I've worked on . Some are webflow , some are wordpress and some are custom built from technology( javascript, react). All ended up going pretty nicely.</p> */}
    </div>
      <ProjectUi projects={projects} />
      <Cta/>
        
    </div>
  )
}





 export default Project